import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify';
import moment from 'moment';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import AppContext from '../../../AppContext';
import { IKHChamCong, ITableTimesheet, ITimesheet } from '../models/timesheetModels';
import { EXPORT_FILE_NAME, RESPONSE_STATUS_CODE, SEARCH_OBJECT_MAX_SIZE, TYPE, TYPE_REF, VARIABLE_STRING } from '../../constants/moduleConsts';
import { searchSymbol } from '../../category/services/symbolServices';
import { approveTimeSheet, autoUpdateTimeSheet, searchTimeSheet, updateTimeSheet } from '../services/timesheetServices';
import { exportToFile, hasAuthority } from '../../utils/functionUtils';
import { PERMISSION_ABILITY, PERMISSIONS } from '../../../constants';
import { CODE_WEEKEND } from '../constants/timesheetConsts';
import { exportTimeSheet } from '../../services/exportFileServices';
import { GroupButton } from '../../components/GroupButton';
import { KTSVG } from '../../../../_metronic/helpers';
import TableTimesheets from './TableTimesheet';
import TextValidator from '../../components/input/text-validator';
import { CODE_STATUS } from '../../manager-request/constants/managerRequestConsts';
import { ConfirmDialog } from '../../components/dialogs';

export interface IPropsTimesheetForm {
  timesheetInfo: ITimesheet;
  handleClose: () => void;
  handleCloseAndSearch: () => void;
}

const TimesheetForm = (props: IPropsTimesheetForm) => {
  const { timesheetInfo, handleClose, handleCloseAndSearch } = props;
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const refsArray = useRef<any[]>([]);

  const [initDataTable, setInitDataTable] = useState<ITableTimesheet[]>([]);
  const [isView, setIsView] = useState<boolean>(true);
  const [dataKHChamCong, setDataKHChamCong] = useState<IKHChamCong[]>([]);
  const [shouldOpenApproveDialog, setShouldOpenApproveDialog] = useState<boolean>(false);

  const getDataKHChamCong = async () => {
    try {
      const { data } = await searchSymbol(SEARCH_OBJECT_MAX_SIZE);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        const listData = data.data.content || [];
        listData.forEach((item: IKHChamCong) => item.isEdited = true);
        setDataKHChamCong(listData);
        return;
      }
      toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }

  useEffect(() => {
    getDataKHChamCong();
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async () => {
    try {
      setPageLoading(true);
      let { phongBanId, nam, thang } = timesheetInfo;
      let searchObject = {
        ...SEARCH_OBJECT_MAX_SIZE,
        nam,
        thang,
        phongBanId
      };

      const { data } = await searchTimeSheet(searchObject);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setInitDataTable(data?.data?.content || [])
        setIsView(true);
        return;
      }
      toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      let dataTable: ITableTimesheet[] = handleConvertTableRef(refsArray.current, [...initDataTable]);

      let { data } = await updateTimeSheet(handleCheckEdited(dataTable))
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        handleSearch();
        return;
      }
      toast.warn(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const setRefsArray = useCallback((index: any, key: any, typeRef: string = TYPE_REF.INPUT_REF, id: string) => (element: any) => {
    let newElement = element ? element : {}
    newElement.typeRef = typeRef;
    newElement.id = id;
    refsArray.current[index] = refsArray.current[index] || {};
    refsArray.current[index][key] = newElement;
  }, []);

  const handleConvertTableRef = (data: any[], dataTable: ITableTimesheet[] = []): any => {
    let dataArray: any[] = dataTable.map(item => ({ ...item }));

    for (let itemRef of data || []) {
      let indexItem = dataArray.findIndex((itemBN: ITableTimesheet) => itemBN?.id === itemRef?.[VARIABLE_STRING.EMPLOYEE]?.id);

      if (indexItem < 0) return;

      for (let [key, value] of Object.entries(itemRef)) {
        if (key === VARIABLE_STRING.EMPLOYEE) continue;

        let listValue = (value as any).getValue();
        let itemValue = checkIsEditedItem(dataArray[indexItem][key], listValue?.[0])

        dataArray[indexItem][key] = itemValue
      }
    }

    return dataArray;
  }

  const checkIsEditedItem = (itemBefore: IKHChamCong | null | undefined, itemAfter: IKHChamCong | null | undefined) => {
    if (itemBefore && !itemAfter) {
      return {
        id: itemBefore?.defaultCode === CODE_WEEKEND ? itemBefore?.id : null,
        isEdited: itemBefore?.defaultCode !== CODE_WEEKEND
      }
    };

    if ((!itemBefore && !itemAfter) || (itemBefore && itemAfter && itemBefore?.id === itemAfter?.id)) {
      return {
        id: itemBefore?.id,
        isEdited: false
      }
    };

    return {
      id: itemAfter?.id,
      isEdited: true
    }
  }

  const handleCheckEdited = (dataTable: ITableTimesheet[]) => {
    return dataTable.filter((item: ITableTimesheet) => {
      let isCheck = Object.entries(item).some(([key, value]) => value?.isEdited);
      if (!isCheck) return isCheck;

      for (let [key, value] of Object.entries(item)) {
        if (typeof value === TYPE.OBJECT && !value?.id && !value?.isEdited) (item as any)[key] = null
      }
      return isCheck;
    }) || []
  }

  const handleIsView = useCallback((isViews: boolean) => {
    setPageLoading(true)
    setTimeout(() => {
      setIsView(isViews)
    }, 10)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleExportExcel = () => {
    if (!initDataTable.length) {
      toast.warning(lang("GENERAL.NO_DATA_TO_EXPORT"));
      return;
    }

    let { donViCongTacCode, donViCongTacText, phongBanId, phongBanText, nam, thang } = timesheetInfo;
    let searchObject = {
      ...SEARCH_OBJECT_MAX_SIZE,
      nam,
      thang,
      phongBanId,
      phongBanText,
      orgCode: donViCongTacCode,
      orgText: donViCongTacText,
    };
    exportToFile({
      exportAPI: () => exportTimeSheet(searchObject),
      fileName: `${EXPORT_FILE_NAME.BANG_CHAM_CONG}${thang ? " tháng " + thang
        : ""}${nam ? " năm " + nam : ""}`,
      setPageLoading
    });
  }

  const updateAutoTimeSheets = async () => {
    try {
      setPageLoading(true);
      let { phongBanId, phongBanText, nam, thang } = timesheetInfo;
      let searchObject = {
        nam,
        thang,
        phongBanId,
        phongBanText
      };

      const { data } = await autoUpdateTimeSheet(searchObject);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TOAST.EDIT.SUCCESS"));
        handleSearch();
      } else toast.warning(data?.message);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleCloseForm = () => {
    setShouldOpenApproveDialog(false);
  }

  const handleConfirm = async () => {
    try {
      setPageLoading(true);
      const { data } = await approveTimeSheet(timesheetInfo.id);
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success("Duyệt bảng công thành công");
        handleCloseAndSearch();
        handleCloseForm();
      } else toast.warn(data?.message);
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  return (
    <div>
      <div className="header-form-action">
        <GroupButton type="btn-back" handleClose={handleClose} />
        <div className="flex gap-4">
          {!isView && (
            <GroupButton
              type="btn-cancel"
              handleCloseUpdateDialog={() => handleIsView(true)}
            />
          )}
          {isView && timesheetInfo?.trangThai?.code !== CODE_STATUS.DA_DUYET 
            && hasAuthority(PERMISSIONS.BANG_CHAM_CONG, PERMISSION_ABILITY.APPROVE) && (
            <GroupButton handleEvent={() => setShouldOpenApproveDialog(true)}>{lang("BTN.APPROVE")}</GroupButton>
          )}
          {isView ? (hasAuthority(PERMISSIONS.BANG_CHAM_CONG, PERMISSION_ABILITY.UPDATE)
            && timesheetInfo?.trangThai?.code !== CODE_STATUS.DA_DUYET && (
              <>
                {timesheetInfo?.nam && timesheetInfo?.thang &&
                    moment([timesheetInfo?.nam, Number(timesheetInfo?.thang) - 1, 1]).isSameOrAfter(moment(), "month") &&
                    <GroupButton handleEvent={updateAutoTimeSheets} className="spaces mr-4">{lang("BTN.UPDATE")}</GroupButton>
                  }
                <GroupButton type="btn-edit" handleSaveEdit={() => handleIsView(false)} />
              </>
            )
          ) : (
            <GroupButton type="btn-save" handleSubmit={handleSubmit} />
          )}
        </div>
      </div>
      <div className="form-content-scroll">
        <Row className="g-3 pt-3">
          <Col xs={12} sm={6} md={5} lg={4} xl={3} xxl={3}>
            <TextValidator
              isRequired
              lable={lang("GENERAL.CURRENT_ORGANIZATION")}
              options={[]}
              value={timesheetInfo?.donViCongTacText || ""}
              name={VARIABLE_STRING.DON_VI_CONG_TAC}
              readOnly
            />
          </Col>
          <Col xs={12} sm={6} md={5} lg={4} xl={3} xxl={3}>
            <TextValidator
              isRequired
              readOnly
              lable={lang("INPUT.DEPARTMENTS")}
              options={[]}
              value={timesheetInfo?.phongBanText || ""}
              name={VARIABLE_STRING.PHONG_BAN}
            />
          </Col>
          <Col xs={6} sm={3} md={2} lg={2} xl={1} xxl={1}>
            <TextValidator
              isRequired
              readOnly
              lable={lang("GENERAL.YEAR")}
              options={[]}
              value={String(timesheetInfo?.nam) || ""}
              name={VARIABLE_STRING.YEAR}
              valueSearch="name"
            />
          </Col>
          <Col xs={6} sm={3} md={2} lg={2} xl={1} xxl={1}>
            <TextValidator
              isRequired
              readOnly
              lable={lang("GENERAL.MONTH")}
              options={[]}
              value={timesheetInfo?.thang || ""}
              name={VARIABLE_STRING.MONTH}
              valueSearch="code"
            />
          </Col>
          <Col xs={6} sm={3} md={3} lg={2} xl={2} xxl={2}>
            <TextValidator
              isRequired
              readOnly
              lable={lang("GENERAL.STATUS")}
              value={timesheetInfo?.trangThai?.name || ""}
              name="trangThai"
            />
          </Col>
          <Col xs={6} sm={3} md={3} lg={2} xl={2}>
            <TextValidator
              name="ngayDuyet"
              lable={lang("DATE.APPROVE")}
              type="date"
              value={timesheetInfo?.ngayDuyet || ""}
              readOnly
            />
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} xxl={3}>
            <TextValidator
              lable={lang("WELFARE.ACTUAL_COST.APPROVED_PEOPLE")}
              readOnly
              options={[]}
              value={timesheetInfo?.nguoiDuyetText || ""}
              name="nguoiDuyet"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className='relative mb-3'>
              <p className='fw-bold text-uppercase fs-1 text-center m-0'>bảng chấm công</p>
              <p className='fw-bold fs-4 text-center m-0'>{`Tháng ${timesheetInfo.thang ?? "....."} năm ${timesheetInfo.nam ?? "....."}`}</p>
              {isView && (
                <GroupButton className="position-absolute bottom-0 end-0" outline handleEvent={handleExportExcel}>
                  <KTSVG path="/media/icons/export-excel.svg" />{" "}
                  {lang("BTN.EXPORT")}
                </GroupButton>
              )}
            </div>
            <TableTimesheets
              data={[...initDataTable]}
              dateSearch={timesheetInfo}
              dataKHChamCong={dataKHChamCong}
              isView={isView}
              refsArray={refsArray}
              setRefsArray={setRefsArray}
            />
          </Col>
        </Row>
        <Col xs={12} className='fw-bold pt-2'>Kí hiệu chấm công:</Col>
        <Row className='ps-6'>
          {dataKHChamCong.map((item: any) => {
            return <Col xs={3} className='spaces flex fs-12'>
              <p className='m-0'>{item?.name}</p>:
              <p className='m-0 fw-bold ps-1 color-crimson-red'>{item?.code}</p>
            </Col>
          })}
        </Row>
      </div>
      {shouldOpenApproveDialog &&
        <ConfirmDialog
          show={true}
          title={lang("DIALOG.CONFIRM")}
          message="Bạn có chắc chắn muốn duyệt bảng công?"
          cancel={lang("BTN.CLOSE")}
          onCancelClick={handleCloseForm}
          yes={lang("BTN.CONFIRM")}
          onYesClick={handleConfirm}
        />
      }
    </div>
  )
}

export default TimesheetForm;
