import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import TableGrouping, { IColumnsGrouping } from '../../components/table-grouping/TableGrouping';
import AppContext from '../../../AppContext';
import { IAttribution } from '../models/timesheetModels';
import { getListWorkDay } from '../../category/services/symbolServices';
import { RESPONSE_STATUS_CODE, TYPE_REF, VARIABLE_STRING } from '../../constants/moduleConsts';
import useMultiLanguage from '../../../hook/useMultiLanguage';
import { toast } from "react-toastify";
import Autocomplete from '../../components/input/autocomplete';
import { CODE_WEEKEND, NUMBER_SHEET_TIMESHEET_OVERVIEW } from '../constants/timesheetConsts';
import { variantStandardStyle } from '../../components/input/autocomplete/styleJS';
import { checkWeekend } from '../../utils/functionUtils';

function TableTimesheets(props: any) {
    const { refsArray, setRefsArray, data, dataKHChamCong, isView = true, dateSearch } = props;
    const { setPageLoading } = useContext(AppContext);
    const { lang } = useMultiLanguage();

    const [listAttribution, setListAttribution] = useState<IAttribution[]>([]);

    const getListAttribution = useCallback(async (searchData: any = {}) => {
        try {
            const { data } = await getListWorkDay(searchData);
            if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
                setListAttribution(data.data.content);
            } else toast.warning(data?.message);
        } catch (error) {
            toast.error(lang("GENERAL.ERROR"));
        }
    }, [lang]);
    
    useEffect(() => {
        getListAttribution();
    }, [getListAttribution]);

    const columns = ({ setRefsArray, dataKHChamCong, isView, dateSearch }: any): IColumnsGrouping[] => {
        const listColumn: IColumnsGrouping[] = [
            {
                name: "STT",
                field: "stt",
                render: (row: any, index: number, stt: number) => <span ref={setRefsArray && setRefsArray(index, VARIABLE_STRING.EMPLOYEE, TYPE_REF.AUTOCOMPLETE_REF, row?.id)}>{stt}</span>,
                isSticky: true,
            },
            {
                name: "Họ và tên",
                field: "employeeText",
                headerStyle: {
                    minWidth: "170px"
                },
                cellStyle: {
                    textAlign: "left"
                },
                isSticky: true,
            },
            {
                name: "Khoa phòng",
                field: "phongBanText",
                isSticky: true,
                headerStyle: {
                    minWidth: "200px"
                },
                cellStyle: {
                    textAlign: "left"
                },
            },
            {
                name: "Cấp bậc chức vụ",
                field: "chucDanhText",
                headerStyle: {
                    minWidth: "200px"
                },
                cellStyle: {
                    textAlign: "left"
                },
            },
        ];

        const listDate = [];
        const dayOfMonth = new Date(dateSearch.nam, dateSearch?.thang, 0).getDate();

        for (let i = 1; i <= dayOfMonth; i++) {
            listDate.push({
                name: String(i),
                field: `ngay${i}`,
                headerStyle: {
                    minWidth: "95px",
                },
                className: checkWeekend(dateSearch.nam, dateSearch.thang, i) ? "isWeekend" : "",
                render: (row: any, index: number) => {
                    return (
                        isView
                            ? <span>{row?.[`ngay${i}`]?.defaultCode === CODE_WEEKEND ? "" : row?.[`ngay${i}`]?.code}</span>
                            : <Autocomplete
                                options={dataKHChamCong}
                                placeholder='Nhập'
                                name={`ngay${i}`}
                                defaultValue={row?.[`ngay${i}`]?.code === CODE_WEEKEND ? "" : row?.[`ngay${i}`]}
                                valueSearch="code"
                                styles={variantStandardStyle("26px", checkWeekend(dateSearch.nam, dateSearch.thang, i))}
                                isRefValue={true}
                                getOptionLabel={(option) => option?.code}
                                menuPortalTarget={document.body}
                                ref={setRefsArray && setRefsArray(index, `ngay${i}`, TYPE_REF.AUTOCOMPLETE_REF, row?.id)}
                            />
                    )
            }});
        }
        
        listColumn.push({
            name: "Ngày trong tháng",
            field: "date",
            headerStyle: {
                minWidth: "180px"
            },
            child: listDate
        });

        const listStatistical: IColumnsGrouping[] = [];
        listAttribution.forEach((attribution) => {
            attribution.isShowed.includes(NUMBER_SHEET_TIMESHEET_OVERVIEW) && listStatistical.push({
                name: attribution?.name,
                field: attribution?.fieldName,
                headerStyle: {
                    minWidth: "180px"
                },
            })
        });

        listColumn.push({
            name: "Quy ra công",
            field: "statistical",
            headerStyle: {
                minWidth: "180px"
            },
            child: listStatistical
        });
        
        return listColumn;
    }
    
    const memoizedTable = useMemo(
        () => {
            setTimeout(() => !isView && setPageLoading(false), 10)
            if (refsArray) {
                refsArray.current = [];
            }
            return (
                <TableGrouping
                    className="table-custom"
                    id="timeSheets"
                    columns={columns({
                        setRefsArray,
                        dataKHChamCong,
                        isView,
                        dateSearch
                    })}
                    data={data}
                    prefixDataField="data"
                    prefixTitleField="phongBanText"
                />
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setRefsArray, data, dataKHChamCong, isView]
    );

    return (
        <div>{memoizedTable}</div>
    )
}

export default TableTimesheets