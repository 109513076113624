import moment from "moment"
import { FORMAT_DATE, SEARCH_OBJECT_MAX_SIZE } from "../../constants/moduleConsts"
import { ITimesheet } from "../models/timesheetModels"
import { findList } from "../../utils/functionUtils"
import { CODE_STATUS, LIST_STATUS } from "../../manager-request/constants/managerRequestConsts"

export const INIT_TIME_SHEETS: ITimesheet = {
    ...SEARCH_OBJECT_MAX_SIZE,
    id: "",
    donViCongTac: null,
    donViCongTacCode: "",
    donViCongTacId: "",
    donViCongTacText: "",
    phongBan: null,
    phongBanId: "",
    phongBanText: "",
    trangThai: findList(LIST_STATUS, CODE_STATUS.TAO_MOI),
    nam: Number(moment().format(FORMAT_DATE.YYYY)),
    thang: Number(moment().format(FORMAT_DATE.MM)),
    tableTimesheet: []
}

export const CODE_WEEKEND = "WKN"

export const NUMBER_SHEET_TIMESHEET_OVERVIEW = 1