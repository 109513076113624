import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { PERMISSIONS, PERMISSION_ABILITY } from "../../constants";
import useMultiLanguage from "../../hook/useMultiLanguage";
import InputSearch from "../components/input/InputSearch";
import TableCustom, { columnNamesType } from "../components/table-custom/TableCustom";
import { hasAuthority } from "../utils/functionUtils";
import AppContext from "../../AppContext";
import { GroupButton } from "../components/GroupButton";
import { INIT_SEARCH_OBJECT, RESPONSE_STATUS_CODE, TYPE } from "../constants/moduleConsts";
import { IShift } from "./models/shiftModels";
import { deleteShift, searchShift } from "./services/shiftServices";
import ShiftForm from "./components/shift/ShiftForm";

const Shift: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [listShift, setListShift] = useState<IShift[]>([]);
  const [openShiftForm, setOpenShiftForm] = useState<boolean>();
  const [shiftInfo, setShiftInfo] = useState<IShift>({} as IShift);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [numberOfElements, setNumberOfElements] = useState<number>(0);
  const [isView, setIsView] = useState<boolean>(false);
  const [searchObject, setSearchObject] = useState<any>(INIT_SEARCH_OBJECT);

  const handleOpenShiftForm = (info: any) => {
    setIsView(true);
    setOpenShiftForm(true);
    setShiftInfo(info);
  };

  const handleCloseAndSearch = () => {
    handleSearch();
    handleClose();
  }

  const handleClose = () => {
    setOpenShiftForm(false);
    setIsView(false);
    setShiftInfo({} as IShift);
  };

  const getListShift = async (searchData?: any) => {
    try {
      setPageLoading(true);
      setSearchObject({ ...searchData })
      const { data } = await searchShift(searchData);
      if(data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        setListShift(data?.data?.content || []);
        setTotalPage(data?.data?.pageable?.totalPages);
        setTotalElements(data?.data?.pageable?.totalElements);
        setNumberOfElements(data?.data?.pageable?.numberOfElements);
      } else {
        toast.warning(data?.message);
      }
    } catch (err) {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleOpenUpdateDialog = () => {
    setOpenShiftForm(true)
  }

  const handleCloseUpdateDialog = () => {
    setIsView(true);
  }

  const handleSearch = (data: any = {}) => {
    const dataSearch: any = {
      ...searchObject,
      ...data
    };
    dataSearch.keyword = dataSearch?.keyword?.trim() || "";
    getListShift(dataSearch);
  };

  useEffect(() => {
    if(searchObject?.pageIndex && searchObject?.pageSize) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject?.pageIndex, searchObject?.pageSize])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      keyword: e.target?.value
    });
  };

  const handleDeleteShift = async (ids: string[]) => {
    try {
      const { data } = await deleteShift(ids)
      if (data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("TIMEKEEPING.SHIFT.DELETE"))
        await getListShift(searchObject);
        return true;
      } else {
        toast.warning(data?.message)
      }
    }
    catch (err: any) {
      toast.error(lang("GENERAL.ERROR"));
    }
  }
  
  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>
    },
    {
      name: lang("TIMEKEEPING.SHIFT.CODE"),
      field: "maCa",
      headerStyle: {
        minWidth: "80px"
      },
      render: (row: any) => <span className="color-steel-blue fw-600">{row?.maCa}</span>
    },
    {
      name: lang("TIMEKEEPING.SHIFT.NAME"),
      field: "tenCa",
      headerStyle: {
        minWidth: "120px"
      },
      cellStyle: {
        textAlign: "left"
      },
    },
    // {
    //   name: lang("TIMEKEEPING.SHIFT.NUMBER_OF_WORKING"),
    //   field: "soGioLam",
    //   headerStyle: {
    //     minWidth: "80px"
    //   }
    // },
    {
      name: lang("TIMEKEEPING.SHIFT.NUMBER_OF_WORK_HOURS"),
      field: "soGioTinhCong",
      headerStyle: {
        minWidth: "80px"
      }
    },
    {
      name: lang("TIMEKEEPING.SHIFT.TIME_START"),
      field: "thoiGianBatDau",
      headerStyle: {
        minWidth: "100px"
      }
    },
    {
      name: lang("TIMEKEEPING.SHIFT.TIME_END"),
      field: "thoiGianKetThuc",
      headerStyle: {
        minWidth: "100px"
      }
    },
    {
      name: lang("TIMEKEEPING.SHIFT.COMPENSATION"),
      field: "caNghiBuText",
      headerStyle: {
        minWidth: "120px"
      }
    }
  ];

  return (
    <div className="spaces px-8 py-12">
      {!openShiftForm ? (
        <>
          <Row className="spaces mb-12 flex-middle">
            <Col xs={5} xl={6} xxl={7} className="p-0">
              <h2 className="breadcrumb-title">{lang("TIMEKEEPING.SHIFT.LIST")}</h2>
            </Col>
            <Col xs={7} xl={6} xxl={5} className="flex">
              <div className="spaces mr-10 flex-1">
                <InputSearch
                  className="border-3px"
                  value={searchObject?.keyword}
                  placeholder="Tìm kiếm theo mã ca, tên ca"
                  handleSearch={() => handleSearch({ pageIndex: 1 })}
                  handleChange={handleChange}
                  isEnter
                />
              </div>
              <GroupButton handleEvent={() => handleSearch({ pageIndex: 1 })}>
                {lang("BTN.SEARCH")}
              </GroupButton>
            </Col>
          </Row>
          <Row className="bg-white">
            <Col className="table-scroll">
              <TableCustom
                id="shift-list"
                data={listShift}
                columns={columns}
                searchObject={searchObject}
                setSearchObject={setSearchObject}
                buttonAdd={hasAuthority(PERMISSIONS.CA_LAM_VIEC, PERMISSION_ABILITY.CREATE)}
                notDelete={!hasAuthority(PERMISSIONS.CA_LAM_VIEC, PERMISSION_ABILITY.DELETE)}
                type={TYPE.MULTILINE}
                fixedColumnsCount={3}
                totalPages={totalPage}
                totalElements={totalElements}
                numberOfElements={numberOfElements}
                handleOpenDialog={handleOpenUpdateDialog}
                handleDoubleClick={(row: any) => handleOpenShiftForm(row)}
                handleDelete={(ids: string[]) => handleDeleteShift(ids)}
              />
            </Col>
          </Row>
        </>
      ) : (
        <ShiftForm
          handleClose={handleClose}
          handleCloseAndSearch={handleCloseAndSearch}
          shiftInfo={shiftInfo}
          handleCloseUpdateDialog={handleCloseUpdateDialog}
          isView={isView}
          setIsView={setIsView}
        />
      )}
    </div>
  )
}

export { Shift };